import * as React from "react"
import Layout from "../../../../components/layout"
import messages from "../../../../i18n/de"
import BlendedCourseModuleInfo from "../../../../components/pageComponents/blendedCourse/moduleInfo"

function BlendedCourseSpecificCourse({ location }) {
  // TODO: translate
  return (
    <Layout location={location} messages={messages}>
      <BlendedCourseModuleInfo langKey="de" moduleNumber={6} />
    </Layout>
  )
}

export default BlendedCourseSpecificCourse
